<template>
  <div>
    <v-row>
      <v-col
        v-for="(configOption, index) in configOptionsList"
        :key="index"
        cols="12"
        md="6"
        lg="4"
      >
        <v-card color="#fff">
          <v-list-item three-line>
            <v-list-item-icon class="pt-5 pl-1">
              <v-icon size="50">{{ configOption.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                {{ configOption.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ configOption.description }}
              </v-list-item-subtitle>
              <div>
                <v-btn
                  color="secondary"
                  small
                  class="mt-2"
                  @click="selectConfig(configOption.component)"
                >
                  <span>Ver configuración</span>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="showConfig">
      <component
        :is="selectedConfig"
        :show="showConfig"
        :businessId="user.businessId"
        @close="closeConfig"
      ></component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedConfig: null,
      showConfig: false,
      configOptionsList: [
        {
          icon: "mdi-ticket",
          name: "Impresión de tickets",
          description:
            "Los parámetros que necesitas para lograr un ticket que se ajuste a tu impresora térmica.",
          component: () => import("./RepairTicketConfig.dialog.vue"),
        },
        {
          icon: "mdi-devices",
          name: "Reparaciones",
          description:
            "Configura el próximo folio para la continuidad de tus órdenes de servicio.",
          component: () => import("./RepairsConfig.dialog.vue"),
        },
        {
          icon: "mdi-account",
          name: "Cuenta",
          description:
            "Modificar la siguiente contraseña que usarás para acceder.",
          component: () => import("./AccountConfig.dialog.vue"),
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    selectConfig(payload) {
      this.selectedConfig = payload;
      this.showConfig = true;
    },

    closeConfig() {
      this.showConfig = false;
      this.selectedConfig = null;
    },
  },
};
</script>
